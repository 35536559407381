<template>
	<div class="zfss">
		<el-tabs type="border-card" v-model="activeName" stretch @tab-click="handleClick">
			<el-tab-pane label="提交申诉" name="tjss">
				<div class="title"><span style="color: #2c3e50;">注意：</span>账户因为切换网络有可能会生成新的账户，请记住自己充值了的账户密码，如果不能看了请切换回充值过的账户！</div>
				<div class="zfssOperate">
					<h4>类型</h4>
					<el-select v-model="zfxlvalue" placeholder="请选择">
					    <el-option
					      v-for="item in options"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					  </el-select>
					<h4 v-if="zfxlvalue==1">账号<span style="font-size: 14px; color: red;">（右上角账户中查看）</span></h4>
					<el-input v-model="inputName" v-if="zfxlvalue==1" placeholder="请输入账号,(右上角账户中查看)" minlength=3 maxlength=19></el-input>
					<!-- <h4>密码</h4>
					<el-input v-model="inputPass" placeholder="请输入密码,(右上角账户中查看)" minlength=3 maxlength=19></el-input>
					 --><h4 v-if="zfxlvalue==1" style="margin-bottom: 6px;">支付截图</h4>
					<el-upload v-if="zfxlvalue==1"
					  class="upload-demo"
					  action="https://jsonplaceholder.typicode.com/posts/"
					  :http-request="uploadFile"
					  accept="image/*"
					  :on-success="successPreview"
					  :on-error="errorPreview"
					  :before-upload="beforePreview"
					  :file-list="fileList"
					  :limit=1
					  list-type="picture">
					  <el-button plain> <i class="el-icon-upload"></i>选择图片</el-button>
					</el-upload>
					<h4 style="margin-bottom: 6px;margin-top: 8px;">说明问题</h4>
					<el-input
					  type="textarea"
					  placeholder="请说明具体问题"
					  v-model="textarea"
					  maxlength="60"
					  show-word-limit>
					</el-input>
					<el-button @click="imgAjax" class="scss" type="danger">送出申诉</el-button>
				</div>
				<!-- <div class="kftsZfss">官方邮箱：91tantan@gmail.com</div> -->
				<div class="kftsZfss">(没有到账用户会以充值成功后计算时间)</div>
				<div class="zfcgfl">
					<div class="sussdiv">支付成功范例:</div>
					<img class="zfbimg" src="../../public/img/wxzfsu.jpg" />
					<img class="zfbimg" style="margin-left: 4%;" src="../../public/img/pay2.jpg" />
					<div class="wxzfbDiv">
						<div>微信账单</div>
						<div>支付宝账单</div>
					</div>
				</div>
			  </el-tab-pane>
			<el-tab-pane label="申诉结果" name="ssjg">
				<el-row>
				  <el-col class="ssjgDiv" :span="24" v-for="(item, index) in zfssData" :key="index" :offset="index > 0 ? 0 : 0">
				    <el-card v-if="item.status!=2" :body-style="{ padding: '0px' }">
				      <div style="padding: 8px;position: relative;">
						<div class="boughtDelet">
						  <el-popconfirm
							title="确定删除吗？"
							@confirm="zfssDelete(item.id)">
							<i slot="reference" class="el-icon-delete"></i>
						  </el-popconfirm>
						</div>
				        <p>申诉状态：
							<span style="color: green;" v-if="item.status==1"><i class="el-icon-success"></i>已完成</span>
							<span style="color: red;" v-else><i class="el-icon-loading"></i>申诉中</span>
						</p>
						<p v-if="item.username!=null && item.username!=''">账号：<span class="zfsscolornum">{{item.username}}</span></p>
						<!-- <p>密码：<span class="zfsscolornum">{{item.password}}</span></p> -->
						<p>详情：<span class="zfsscolornum">{{item.remarks}}</span></p>
				        <div class="bottom clearfix">
				          <time class="time">申诉时间：<span class="zfsscolornum">{{item.createDate}}</span></time>
				        </div>
						 <el-divider v-if="item.reply"><i class="el-icon-user"></i></el-divider>
						 <p v-if="item.reply" style="color: green;">{{item.reply}}</p>
				      </div>
				    </el-card>
				  </el-col>
				</el-row>
			</el-tab-pane>
		</el-tabs>
		<el-dialog
		  title="注意"
		  :visible.sync="centerDialogVisible"
		  width="75%"
		  top="30vh"
		  center>
		  <span style="padding:20px;font-weight: 700;font-size: 16px;display: inline-block;">您当前使用的账号和您输入的账号不一样。请切换您截图的账号密码，然后提交售后。</span>
		  <span slot="footer" class="dialog-footer">
			<el-button @click="imgAjax('jxtj')">继续提交</el-button>
		    <el-button type="primary" @click="toSwitch">切换账户</el-button>
		  </span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
	data() {
	  return {
		  userId:"",
		  inputName:"",
		  inputPass:"",
		  fileList: [],
		  imgupName:"",
		  textarea:"",
		  centerDialogVisible:false,
		  userName:"",
		  zfssData:[],
		  activeName:"tjss",
		  options: [{
			value: '1',
			label: '支付成功后未到账'
		  }, {
			value: '2',
			label: '其它问题'
		  }
		],
		zfxlvalue: '1'
	  }
	},
	created(){
		if(localStorage.getItem('userId_ds')){
			this.userId = localStorage.getItem('userId_ds');
		}else{
			this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
			if(this.userId==null || this.userId==""){
				if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
					this.userId = this.$parent.userId;
				}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
					this.userId = this.$route.query.userId;
				}
			}
		}
		this.getLoginAxios();
		var that = this;
		setInterval(() => {
			if(that.activeName=="ssjg"){
				that.getzfssListAxios();
			}
		},5000);
	},
	methods: {
		successPreview(response, file, fileList){
			if(response.indexOf('上传失败')>=0){
				this.erroropen4(response);
				this.fileList = [];
			}
		},
		errorPreview(err, file, fileList){
			this.erroropen4(err);
			this.fileList = [];
		},
		toSwitch(){
			this.$router.push({
				name: "Switch",
				query: {
					userId:this.userId
				}
			});
		},
		beforePreview(file){
		  const isJPG = file.type;
		  const isLt2M = file.size / 1024 / 1024 < 50;
		  if (isJPG!='image/jpeg' && isJPG!='image/gif' && isJPG!='image/png' && isJPG!='image/jpg') {
			this.erroropen4('上传失败,类型不匹配！');
			this.fileList = [];
		  }
		  if (!isLt2M) {
			this.erroropen4('上传失败,文件过大！');
			this.fileList = [];
		  }
		  return isJPG && isLt2M;
		},
		erroropen4(centtext) {
		  this.$message({
			showClose: true,
			message: centtext,
			type: 'error',
			offset:240,
		  });
		},
		uploadFile(item) {
		  let that = this;
		  const loading = this.$loading({
		    lock: true,
		    text: '正在上传图片...',
		    spinner: 'el-icon-loading',
		    background: 'rgba(0, 0, 0, 0.7)'
		  });
		  let fileObj = item.file;
		  const form = new FormData(); // FormData 对象
		  form.append("file", fileObj); // 文件对象  'upload'是后台接收的参数名
		  this.axios.post("https://vocdewresb.com/pic/", form)
			.then(function (response) {
				 loading.close();
				 if(response.status==200){
					 if(response.data.indexOf('上传失败')>=0){
					 	that.erroropen4(response.data);
					 	that.fileList = [];
					 }else{
						//if(response.data.indexOf('png')>=0){
							that.imgupName = response.data;
						//}
					 }
				 }
			})
			.catch(function (error) {
			  loading.close();
			  that.erroropen4('上传失败,请重新上传！');
			  that.fileList = [];
			  console.log(error);
			});
		},
		imgAjax(tjId){
			  let that = this;
			  /* if(this.inputPass.length<=0){
				this.erroropen4("请输入密码！");
				return;
			  } */
			 if(that.zfxlvalue==1){
				 if(this.inputName.length<=0){
					this.erroropen4("请输入账号！");
					return;
				 }
				  if(this.imgupName.length<=0){
					this.erroropen4("请上传图片！");
					return;
				  }
				  if(tjId=="jxtj"){
				   this.centerDialogVisible = false;
				  }else{
				  if(this.userName!=this.inputName){
					  this.centerDialogVisible = true;
					  return;
				  }
				  }
			  }
			  if(that.zfxlvalue==2){
				if(this.textarea.length<=0){
					this.erroropen4("请说明您的问题，并留下您得邮箱。");
					return;
				}
			  }
			  const loading = this.$loading({
			    lock: true,
			    text: '正在提交...',
			    spinner: 'el-icon-loading',
			    background: 'rgba(0, 0, 0, 0.7)'
			  });
			  var params = new URLSearchParams();
			  params.append('userId', this.userId);
			  if(that.zfxlvalue==1){
				 params.append('username', this.inputName);
				 params.append('imgs', this.imgupName);
			  }
			  //params.append('password', this.inputPass);
			  params.append('remarks', this.textarea);
			  //http://vberw.vocdewresb.com:8080/tantan/mobile/tantan/zfss/save
			  this.axios.post(this.GLOBAL.serverSrcSs+'mobile/tantan/zfss/save',params)
			  .then(function (response) {
				 loading.close();
				 if(response.data.code==1){
					that.successOpen2('提交成功,耐心等待处理');
					that.getzfssListAxios();
					that.imgupName="";
					that.inputName="";
					that.inputPass="";
					that.textarea="";
					setTimeout(() => {
						that.activeName="ssjg";
					}, 1000)
				 }
			})
			.catch(function (error) {
			  loading.close();
			  that.erroropen4('提交失败，请稍后重试');
			});
		},
		getLoginAxios(){
			var that = this;
			var params = new URLSearchParams();
			params.append('name', localStorage.getItem('username'));
			params.append('password', localStorage.getItem('password'));
			params.append('type', "2");
			this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/login',params)
			  .then(function (response) {
				  if(response.data.code==1){
					 var datas = response.data.data;
					 that.userName = datas.name;
					 localStorage.setItem("username",datas.name);
					 localStorage.setItem("password",datas.password);
					 localStorage.setItem("userId_ds",datas.id);
				  }
			  })
			  .catch(function (error) {
				console.log(error);
			  });
		},
		handleClick(tab, event) {
			if(tab.index=="1"){
				this.getzfssListAxios();
			}
		},
		getzfssListAxios(){
			var that = this;
			var params = new URLSearchParams();
			params.append('userId', this.userId);
			this.axios.post(this.GLOBAL.serverSrcSs+'mobile/tantan/zfss/list',params)
			  .then(function (response) {
				  if(response.data.code==1){
					that.zfssData = response.data.data;
				  }
			  })
			  .catch(function (error) {
				console.log(error);
			  });
		},
		zfssDelete(zfssId){
			var that = this;
			var params = new URLSearchParams();
			params.append('id', zfssId);
			params.append('userId', this.userId);
			this.axios.post(this.GLOBAL.serverSrcSs+'mobile/tantan/zfss/delete',params)
			  .then(function (response) {
				  if(response.data.code==1){
					that.successOpen2("删除成功");
					that.zfssData = response.data.data;
				  }else{
					that.erreOpen("删除失败");
				  }
			  })
			  .catch(function (error) {
				console.log(error);
			  });
		},
		successOpen2(centent) {
			this.$message({
			  showClose: true,
			  message: centent,
			  type: 'success',
			  offset:250,
			});
		},
		erreOpen(centtext) {
		  this.$message({
			showClose: true,
			message: centtext,
			type: 'error',
			offset:150,
		  });
		},
	}
}
</script>

<style>
	.zfss{
		margin: 10px;
		max-width: 940px;
		margin: 10px auto;
		padding: 8px;
	}
	.zfss .el-button{
		font-size: 16px;
	}
	.zfss .title{
		color: red;
		font-size: 14px;
		margin: 15px auto;
		max-width: 940px;
	}
	.zfssOperate{
		max-width: 940px;
	    margin: 10px auto;
	    background: white;
	    padding: 20px 15px;
	    border-radius: 10px;
	    margin-bottom: 0px;
	}
	.zfssOperate input{
		margin: 6px 0px;
	}
	.zfss .zfssOperate h4{
		margin: 0px;
		font-weight: normal;
	}
	.zfss .scss{
		width:100%;
		margin-top: 30px;
	}
	.zfss .el-select{
		width: 100%;
	}
	.zfssOperate .el-upload--picture-card{
		width: 100px;
		height: 100px;
		line-height: 100px;
	}
	.kftsZfss{
		color: red;
		text-align: center;
	}
	.zfcgfl{
		max-width: 940px;
		margin: 10px auto;
		margin-bottom: 20px;
	}
	.zfcgfl .sussdiv{
		margin-top: 20px;
		margin-bottom: 10px;
	}
	.zfbimg{
		width: 48%;
	}
	.el-upload{
		width: 100%;
	}
	.el-upload .el-button{
		width: 100%;
	}
	.wxzfbDiv div{
		width: 50%;
		display: inline-block;
		text-align: center;
		margin-top: 10px;
	}
	.ssjgDiv{
		margin: 10px 0px;
	}
	.ssjgDiv p{
		line-height: 25px;
	}
	.zfsscolornum{
		color: #909399;
	}
</style>
